var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[_c('div',{class:_vm.$style.header},[_c('div',[(_vm.name)?_c('div',{class:_vm.$style.title},[_vm._v(_vm._s(_vm.name))]):_vm._e(),(_vm.message)?_c('div',{class:_vm.$style.message},[_vm._v(_vm._s(_vm.message))]):_vm._e()]),(!_vm.readonly)?_c('VBtn',{staticClass:"float-right",attrs:{"depressed":"","small":"","color":"primary","disabled":_vm.pending},on:{"click":_vm.onEdit}},[(_vm.pending)?[_vm._v("Идет загрузка файла")]:[_vm._v(_vm._s(_vm.empty ? 'Загрузить документ': 'Загрузить новую версию'))]],2):_vm._e()],1),(!_vm.empty)?_c('div',{class:_vm.$style.table},[_c('VDataTable',{attrs:{"headers":_vm.headers,"items":_vm.files,"item-class":_vm.rowClassControl,"mobile-breakpoint":0,"show-expand":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.end",fn:function(ref){
var end = ref.item.end;
return [_vm._v(_vm._s(end || '-'))]}},{key:"item.status",fn:function(ref){
var status = ref.item.status;
return [_vm._v(_vm._s(status || '-'))]}},{key:"item.history",fn:function(ref){
var history = ref.item.history;
return [_vm._v(" Версия "+_vm._s(( history.length || 0 ) + 1)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[(item.value)?_c('VBtn',{staticClass:"ml-2",attrs:{"depressed":"","x-small":""},on:{"click":function () { return _vm.onDownload({ id: item.value }); }}},[_vm._v("Скачать")]):_c('VBtn',{staticClass:"ml-2",attrs:{"depressed":"","x-small":"","disabled":""}},[_vm._v("В архиве")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var history = ref.item.history;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":headers.length}},[_c('div',{class:_vm.$style.inner},[_c('VDataTable',{attrs:{"headers":headers,"items":history,"item-class":_vm.rowClassControl,"mobile-breakpoint":0,"show-expand":"","hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.end",fn:function(ref){
var end = ref.item.end;
return [_vm._v(_vm._s(end || '-'))]}},{key:"item.status",fn:function(ref){
var status = ref.item.status;
return [_vm._v(_vm._s(status || '-'))]}},{key:"item.history",fn:function(ref){
var index = ref.index;
return [_vm._v(" Версия "+_vm._s(history.length - index)+" ")]}}],null,true)})],1)])]}}],null,false,2472918841)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }